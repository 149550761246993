import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
// import { HashRouter as Router, Routes, Route } from 'react-router-dom';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import HomePage from "./pages/home.jsx";
import PricingPage from "./pages/pricing.jsx";
import SignupPage from "./pages/signup.jsx";
import PrivacyPolicyPage from "./pages/privacypolicy.jsx";
import TermsPage from "./pages/terms.jsx";
import CaseStudy from "./pages/caseStudy";
import NotFoundPage from "./pages/notFoundPage.jsx";
import SolutionsPage from "./pages/solutions.jsx";

import "./styles/tailwind.css";
import "./styles/main.css";
import "./styles/Pablooflow.css";
import "./styles/hero-pablooprod/pablooprodFont.css";
import "./styles/hero-pablooprod/pablooprodStyle.css";

import reportWebVitals from "./reportWebVitals";
import TermsComponent from "./components/TermsComponent.js";
import ThankYou from "./pages/thankYou";
import { Login } from "./pages/login";
import Features from "./pages/features";
import ProductsPage from "./pages/products.jsx";
import ScheduleCall from "./pages/scheduleCall";

// Google Analytics
window.dataLayer = window.dataLayer || [];
window.gtag = function () {
  window.dataLayer.push(arguments);
};
window.gtag("js", new Date());
window.gtag("config", "G-RY13LC0YDT");

const TrackPageViews = () => {
  const location = useLocation();
  useEffect(() => {
    const pageTitle = getPageTitle(location.pathname);
    document.title = pageTitle;
    window.gtag("event", "page_view", {
      page_title: pageTitle,
      page_path: location.pathname,
    });
  }, [location]);
  return null;
};

if (process.env.NODE_ENV !== "development") {
  console.log = () => {}
}

const getPageTitle = (pathname) => {
  switch (pathname) {
    case "/":
      return "Pabloo | Home";
    case "/pricing":
      return "Pabloo | Pricing";
    case "/signup":
      return "Pabloo | Sign Up";
    case "/privacy-policy":
      return "Pabloo | Privacy Policy";
    case "/terms-and-conditions":
      return "Pabloo | Terms and Conditions";
    case "/case-study":
      return "Pabloo | Case Study";
    case "/case-study/parks-project-study":
      return "Pabloo | Parks Project CaseStudy";
    case "/case-study/balodana-study":
      return "Pabloo | Balodana CaseStudy";
    case "/case-study/planted-material-study":
      return "Pabloo | Planted Materials CaseStudy";
    case "/case-study/stzy-study":
      return "Pabloo | Stzy CaseStudy";
    case "/login":
      return "Pabloo | Login";
    case "/thank-you":
      return "Pabloo | Purchase";
    case "/features":
      return "Pabloo | Features";
    case "/solutions":
      return "Pabloo | Solutions";
    case "/products":
      return "Pabloo | Products";
    case "/scheduleCall":
      return "Pabloo | Schedule Call";
    default:
      return "Not Found";
  }
};

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <Router>
      <TrackPageViews />
      <Routes>
        <Route path="/" rel="Home" element={<HomePage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-and-conditions" element={<TermsPage />} />
        <Route path="/case-study" element={<CaseStudy />} />
        <Route path="/login" element={<Login />} />
        <Route path="/features" element={<Features />} />
        <Route path="/solutions" element={<SolutionsPage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/scheduleCall" element={<ScheduleCall />} />

        <Route path="/thank-you" element={<ThankYou />} />

        <Route path="*" element={<NotFoundPage />} />

        {/* For future pages added */}
        {/* <Route path="/" element={<HomePage/>}/>  */}
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
