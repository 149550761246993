//React imports
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import { useNavigate } from "react-router-dom";

//Imports the components
import PablooHeroComponent from "../components/home/PablooHeroComponent.js";
import CarouselComponent from "../components/CarouselComponent.js";
import StatsBlockComponent from "../components/home/StatsBlockComponent.js";
import TribeSection from "../components/tribe/TribeSection.js";
import SpendComponent from "../components/home/SpendComponent.js";
import PointsComponent from "../components/home/PointsComponent.js";
import CardsComponent from "../components/CardsComponent.js";
import IntegrationsComponent from "../components/IntegrationsComponent.js";
import CalculatorComponent from "../components/calculator/CaculatorComponent.js";
import StackComponent from "../components/home/StackComponent.js";
import TestimonialComponent from "../components/home/TestimonialComponent.js";
import SolutionComponent from "../components/SolutionComponent.js";
import FooterComponent from "../components/home/FooterComponent.js";
import NavBarComponent from "../components/navBar/NavBarComponent.js";
import NavBarComponentTwo from "../components/navBar/splitNavBar/NavBarComponentTwo.js";

import SignupComponent from "../components/signUp/SignupComponent.js";

import HeaderComponent from "../components/HeaderComponent.js";
import FaqComponent from "../components/home/FaqComponent.js";
import ContactComponent from "../components/contact/ContactComponent.js";
import VideoPlayer from "../components/videoPlayer/VideoPlayer";

// import pricingPage from './pricingPage.jsx';

//Imports assets such as images
import { ProblemAndSolution } from "../components/home/problemAndSolutionView";
import { useSearchParams } from "react-router-dom";

import { Component } from "react";

export default function HomePage() {
  const productRef = useRef();
  const resourcesRef = useRef();
  const testimonialsRef = useRef();

  const scrollWithOffset = (element, offset) => {
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: elementPosition - offset,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScrollToSection = (event) => {
      const section = event.detail.section;
      let offset = 0;

      // Determine the offset based on viewport size
      if (window.innerWidth <= 768) {
        // Mobile offsets
        switch (section) {
          case "testimonials":
            offset = 40;
            break;
          case "product":
            offset = 120;
            break;
          case "resources":
            offset = 70;
            break;
          default:
            offset = 70; // Default mobile offset
            break;
        }
      } else {
        // Desktop offsets
        switch (section) {
          case "testimonials":
            offset = 80;
            break;
          case "product":
            offset = 130;
            break;
          case "resources":
            offset = 80;
            break;
          default:
            offset = 130; // Default desktop offset
            break;
        }
      }

      switch (section) {
        case "testimonials":
          scrollWithOffset(testimonialsRef.current, offset);
          break;
        case "product":
          scrollWithOffset(productRef.current, offset);
          break;
        case "resources":
          scrollWithOffset(resourcesRef.current, offset);
          break;
        default:
          break;
      }
    };

    window.addEventListener("scrollToSection", handleScrollToSection);

    const searchParameters = new URLSearchParams(window.location.search);
    const section = searchParameters.get("section");
    if (section) {
      handleScrollToSection({ detail: { section } });
    }

    return () => {
      window.removeEventListener("scrollToSection", handleScrollToSection);
    };
  }, []);

  return (
    <div>
      <ErrorBoundary>
        <div className="updated-page-wrapper">
          {/* <NavBarComponent /> */}
          <NavBarComponent />

          <HeaderComponent>
            <PablooHeroComponent />
            <div className="my-8">
              <CarouselComponent />
            </div>
          </HeaderComponent>

          <div className="mb-10">
            <StatsBlockComponent />
          </div>

          <div className="mb-20">
            <ProblemAndSolution />
          </div>

          <div className="mb-20">
            <VideoPlayer />
          </div>

          <div ref={productRef}>
            <TribeSection />
          </div>

          {/* Has the script that messes up animations */}
          {/* <TribeTest/> */}

          <SpendComponent />

          <div ref={resourcesRef}>
            <PointsComponent />
          </div>

          {/*<CardsComponent />*/}

          <IntegrationsComponent />

          <CalculatorComponent />

          <StackComponent />

          <div ref={testimonialsRef}>
            <TestimonialComponent />
          </div>

          <SolutionComponent />

          <FaqComponent />

          {/*<ContactComponent />*/}

          <FooterComponent />
        </div>
      </ErrorBoundary>
    </div>
  );
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error or perform any other necessary actions
    console.error("Error caught:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Display a fallback UI when an error occurs
      return <div>Something went wrong. Please try again later.</div>;
    }

    return this.props.children;
  }
}
